
























































import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import SwitchInput from '@/components/_uikit/controls/SwitchInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import ProfileModule from '@/store/modules/profile'
import SystemModule from '@/store/modules/system'
import {
  UserLargeResource,
  UserNotificationResource,
  UserNotificationsRequest,
  UserType,
  SocialResource,
} from '@/store/types'
import AttachSocialsModal from '@/components/modals/AttachSocialsModal.vue'

@Component({
  components: {
    AttachSocialsModal,
    SwitchInput,
  },
})
export default class Notifications extends Mixins(NotifyMixin) {
  private form: UserNotificationsRequest = {
    checked: [],
    telegramChecked: [],
    isDisabledSite: false,
    isDisabledTelegram: false,
  }

  private get isManager () {
    return SystemModule.interface === 'manager'
  }

  private get notificationsSettings () {
    return ProfileModule.notificationsSettings as UserNotificationResource
  }

  private get information () {
    return ProfileModule.information
  }

  private socials: SocialResource[] = []
  private showAttachSocialsModal = false

  private mounted () {
    this.fetchNotificationsSettings()
    if (!this.information) {
      ProfileModule.fetchInformation()
        .then((response: UserLargeResource) => {
          this.socials = response.socials
          this.showAttachSocialsModal = this.socials.length < ProfileModule.requiredSocials.length
        })
        .catch(this.notifyError)
    } else {
      this.socials = this.information.socials
      this.showAttachSocialsModal = this.socials.length < ProfileModule.requiredSocials.length
    }
  }

  private fetchNotificationsSettings () {
    ProfileModule.fetchNotificationsSettings({
      userType: this.isManager ? UserType.MANAGER : UserType.MASTER,
    })
      .then((response: UserNotificationResource) => {
        this.form = {
          checked: [...response.checked],
          telegramChecked: [...response.telegramChecked],
          isDisabledSite: response.isDisabledSite,
          isDisabledTelegram: response.isDisabledTelegram,
        }
      })
      .catch(this.notifyError)
  }

  private saveNotificationsSettings () {
    ProfileModule.saveNotificationsSettings({
      form: this.form,
      params: {
        userType: this.isManager ? UserType.MANAGER : UserType.MASTER,
      },
    })
      .then(() => {
        this.notifySuccess('Настройки уведомлений сохранены')
      })
      .catch(this.notifyError)
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Уведомления',
    }
  }
}
